import { DsCurrency, DsLocalData } from "@devsalsa/vue-core";
import { ContentOrientationId } from "@/shared/enums/ContentOrientationEnum";
import { DeliveryTypeId } from "@/shared/enums/DeliveryTypeIdEnum";
import { UgcTypeName } from "@/shared/enums/UgcTypeEnum";
import type { ProductExtended } from "@/modules/product/services/ProductService.types";
import type { CampaignExtended } from "@/shared/services/SearchService.types";
import type {
  CampaignCreationFormData,
  UrlReference,
} from "@/modules/campaign/services/CampaignService.types";
import { CampaignPricingModel } from "@/modules/campaign/enums/CampaignPricingModelEnum";
import type { DsSelectOption } from "@devsalsa/vue-core";
import type { Job } from "@/modules/job/services/JobService.types";
import type { RouteLocationNormalized } from "vue-router";
import type { CampaignBundle } from "@/shared/services/AssetsService.types";
import type { CertificationInfo } from "@/shared/services/CertificationService.types";
import { CampaignStatus } from "@/modules/campaign/enums/CampaignStatusEnum";

export default class CampaignCreationLocalData {
  private static defaultInit() {
    return {
      ugc_type: "" as UgcTypeName,
      certification_id: 0,
      pricing_model: CampaignPricingModel.Classic,
      campaign_bundle_id: -1,
      scratch: false,
      private_campaign: 0,
      initiated_from_job: 0,
      invite_creators: [] as number[],
      redirect_route: {} as RouteLocationNormalized,
    };
  }

  private static defaultBasics() {
    return {
      title: "",
      brand_id: 0,
      category_id: 0,
      product: {
        id: 0,
      } as ProductExtended,
      base_cost: 0,
    };
  }

  private static defaultSettings() {
    return {
      orientation: ContentOrientationId.Any,
      director_notes: "",
      max_jobs: 5,
      video_duration: 15,
      amount_video_duration: 0,
      references: [] as UrlReference[],
      special_requirement_keywords: [] as DsSelectOption[],
      special_requirement_keyword_shortcuts: [] as DsSelectOption[],
      caption: "",
      creator_level: 1,
      social_title: "",
      social_description: "",
      product_asins: "",
      rejected_campaign_id: 0,
      coupon: "",
      coupon_bundles: [] as CampaignBundle[],
      prev_bundle: {} as CampaignBundle,
      tiktok_shop_product_url: "",
      tiktok_shop_affiliate_commission: 1,
      subtitles: 0,
      subtitles_cost: 1500,
    };
  }

  private static defaultCreators() {
    return {
      ethnicities: [1, 2, 3, 4, 5],
      genders: [1, 2],
      age_selection: [1, 2, 3, 4],
      auto_approve_creator: 0,
    };
  }

  private static defaultDelivery() {
    return {
      delivery_type: DeliveryTypeId.Reimbursement,
      shipping: 0,
      taxes: 0,
    };
  }

  private static defaultPayment() {
    return {
      credit_card_id: 0,
      use_company_wallet: true,
    };
  }

  private static defaultCreator() {
    return {
      creator_photo: "",
      creator_name: "",
      creator_level: 1,
      certifications: [] as CertificationInfo[],
    };
  }

  static basics = DsLocalData("campaign.creation.basics", this.defaultBasics());
  static delivery = DsLocalData(
    "campaign.creation.delivery",
    this.defaultDelivery()
  );
  static settings = DsLocalData(
    "campaign.creation.settings",
    this.defaultSettings()
  );
  static creators = DsLocalData(
    "campaign.creation.creators",
    this.defaultCreators()
  );
  static payment = DsLocalData(
    "campaign.creation.payment",
    this.defaultPayment()
  );
  static init = DsLocalData("campaign.creation.init", this.defaultInit());
  static creator = DsLocalData(
    "campaign.private.creator",
    this.defaultCreator()
  );
  private static position = DsLocalData("campaign.creation.position", 0);

  static resetWithDefaultValues(ugcType: UgcTypeName) {
    if (CampaignCreationLocalData.init.value) {
      CampaignCreationLocalData.init.value.ugc_type = ugcType;
      CampaignCreationLocalData.init.value.pricing_model =
        CampaignPricingModel.Classic;
      CampaignCreationLocalData.init.value.campaign_bundle_id = -1;
    }

    if (CampaignCreationLocalData.settings.value) {
      CampaignCreationLocalData.settings.value.references = [];
      CampaignCreationLocalData.settings.value.caption = "";
      CampaignCreationLocalData.settings.value.social_title = "";
      CampaignCreationLocalData.settings.value.social_description = "";
      CampaignCreationLocalData.settings.value.product_asins = "";
      CampaignCreationLocalData.settings.value.video_duration = 15;
      CampaignCreationLocalData.settings.value.amount_video_duration = 0;
      CampaignCreationLocalData.settings.value.orientation =
        ContentOrientationId.Any;
      CampaignCreationLocalData.settings.value.coupon = "";
      CampaignCreationLocalData.settings.value.coupon_bundles = [];
      CampaignCreationLocalData.settings.value.prev_bundle =
        {} as CampaignBundle;
      CampaignCreationLocalData.settings.value.tiktok_shop_product_url = "";
      CampaignCreationLocalData.settings.value.tiktok_shop_affiliate_commission = 1;
      CampaignCreationLocalData.settings.value.subtitles = 0;
      CampaignCreationLocalData.settings.value.subtitles_cost = 1500;
    }

    if (
      CampaignCreationLocalData.creators.value &&
      ugcType === UgcTypeName.Bundle
    ) {
      CampaignCreationLocalData.creators.value = this.defaultCreators();
    }
  }

  static setPosition(val: string, update = false) {
    const positionRoute = [
      "CampaignCreationBasics",
      "CampaignCreationSettings",
      "CampaignCreationCreators",
      "CampaignCreationSummary",
    ];

    const key: number =
      positionRoute.includes(val) && positionRoute.indexOf(val)
        ? positionRoute.indexOf(val)
        : 0;

    if (key > CampaignCreationLocalData.position.value || update) {
      CampaignCreationLocalData.position.value = key;
    }
    return val;
  }

  static getPosition() {
    return CampaignCreationLocalData.position.value || 0;
  }

  static clear() {
    this.init.value = this.defaultInit();
    this.settings.value = this.defaultSettings();
    this.basics.value = this.defaultBasics();
    this.delivery.value = this.defaultDelivery();
    this.creators.value = this.defaultCreators();
    this.payment.value = this.defaultPayment();
    this.creator.value = this.defaultCreator();
    this.position.value = 0;
  }

  static initWithCampaign(campaign: CampaignExtended) {
    this.init.value = {
      ugc_type: campaign.ugc_type,
      certification_id: campaign.job_type_id,
      pricing_model: campaign.pricing_model,
      campaign_bundle_id: campaign.campaign_bundle_id,
      scratch: true,
      private_campaign: 0,
      initiated_from_job: 0,
      invite_creators: [] as number[],
      redirect_route: {} as RouteLocationNormalized,
    };
    if (campaign.coupon_applied) {
      this.init.value.campaign_bundle_id = -1;
    }
    if (campaign.pricing_model === CampaignPricingModel.Bundle) {
      this.init.value.ugc_type = UgcTypeName.Bundle;
    }
    this.basics.value = {
      brand_id: campaign.brand_id,
      title: campaign.title,
      category_id: campaign.category_id,
      product: {
        id: campaign.original_product_id,
      } as ProductExtended,
      base_cost: 0,
    };
    this.settings.value = {
      director_notes: campaign.director_notes,
      creator_level: campaign.campaign_creator_level,
      max_jobs: campaign.max_jobs,
      special_requirement_keywords: this.setKeywords(
        campaign.special_requirement_keywords
      ),
      special_requirement_keyword_shortcuts: this.setShortcutKeywords(
        campaign.special_requirement_keywords
      ),
      orientation: campaign.orientation,
      amount_video_duration: 0,
      caption: campaign.caption,
      social_title: campaign.social_title,
      social_description: campaign.social_description,
      references: campaign.references,
      video_duration: campaign.video_duration,
      rejected_campaign_id:
        campaign.status === CampaignStatus.Rejected ? campaign.id : 0,
      product_asins: this.processAsins(campaign.product_asins),
      coupon: "",
      coupon_bundles: [],
      prev_bundle: {} as CampaignBundle,
      tiktok_shop_product_url: campaign.tiktok_shop_product_url,
      tiktok_shop_affiliate_commission:
        campaign.tiktok_shop_affiliate_commission,
      subtitles: campaign.subtitles ? 1 : 0,
      subtitles_cost: 1500,
    };
    this.creators.value = {
      genders: campaign.genders,
      age_selection: campaign.age_selection,
      ethnicities: campaign.ethnicities,
      auto_approve_creator: campaign.auto_approve_creator ? 1 : 0,
    };
    this.payment.value = {
      credit_card_id: campaign.credit_card_id,
      use_company_wallet: campaign.use_company_wallet,
    };
    const delivery = {
      delivery_type: campaign.delivery_type,
      shipping: 0,
      taxes: 0,
    };
    if (campaign.delivery_type === DeliveryTypeId.Reimbursement) {
      const calculation_log = {};
      campaign.calculation_log.forEach((e) =>
        Reflect.set(calculation_log, e.key, e.amount)
      );

      Reflect.set(
        delivery,
        "shipping",
        DsCurrency.fromCents(
          Number(Reflect.get(calculation_log, "reimbursement_shipping_amount"))
        )
      );
      Reflect.set(
        delivery,
        "taxes",
        DsCurrency.fromCents(
          Number(Reflect.get(calculation_log, "reimbursement_taxes_amount"))
        )
      );
    }
    if (campaign.product_deleted) {
      this.basics.value.product = { id: 0 } as ProductExtended;
    }
    this.delivery.value = delivery;
  }

  static initWithCampaignAndJob(campaign: CampaignExtended, job: Job) {
    this.init.value.private_campaign = 1;
    this.init.value.initiated_from_job = job.id;
    this.init.value.invite_creators = [job.creator_id];
    this.creator.value = {
      creator_photo: job.profile_image,
      creator_name: job.creator_name,
      creator_level: job.creator_level,
      certifications: [],
    };

    if (campaign.pricing_model === CampaignPricingModel.Bundle) {
      this.init.value.pricing_model = CampaignPricingModel.Classic;
      this.init.value.campaign_bundle_id = -1;
      this.init.value.ugc_type = campaign.ugc_type;
    }
  }

  static initWithProduct(product: ProductExtended) {
    CampaignCreationLocalData.basics.value.brand_id = product.brand_id;
    CampaignCreationLocalData.basics.value.product = product;
  }

  static prepareFormData(): CampaignCreationFormData {
    const data = {
      title: this.basics.value.title,
      product_id: this.basics.value.product.id,
      use_company_wallet: this.payment.value.use_company_wallet ? 0 : 1,
      credit_card_id: this.payment.value.credit_card_id,
      delivery_type: this.delivery.value.delivery_type,
      reimbursement_shipping_amount: DsCurrency.toCents(
        this.delivery.value.shipping ?? 0
      ),
      reimbursement_taxes_amount: DsCurrency.toCents(
        this.delivery.value.taxes ?? 0
      ),
      special_requirement_keywords:
        [
          ...this.settings.value.special_requirement_keywords,
          ...this.settings.value.special_requirement_keyword_shortcuts,
        ] || [],
      references: CampaignCreationLocalData.settings.value.references
        .filter((reference) => reference.url !== "")
        .map((reference: UrlReference) => reference.url),
      age_selection: this.creators.value.age_selection,
      director_notes: this.settings.value.director_notes,
      ethnicities: this.creators.value.ethnicities,
      genders: this.creators.value.genders,
      job_type_id: this.init.value.certification_id,
      max_jobs: this.settings.value.max_jobs,
      rejected_campaign_id: this.settings.value.rejected_campaign_id
        ? this.settings.value.rejected_campaign_id
        : 0,
      ugc_type: this.init.value.ugc_type,
      creator_level: this.settings.value.creator_level,
      pricing_model: this.init.value.pricing_model,
      campaign_bundle_id: this.init.value.campaign_bundle_id,
      private_campaign: this.init.value.private_campaign,
      bundle_coupon_code: this.settings.value.coupon,
      tiktok_shop_product_url: this.settings.value.tiktok_shop_product_url,
      tiktok_shop_affiliate_commission:
        this.settings.value.tiktok_shop_affiliate_commission,
    } as CampaignCreationFormData;

    if (this.init.value.private_campaign) {
      data.initiated_from_job = this.init.value.initiated_from_job;
      data.invite_creators = this.init.value.invite_creators;
    }

    return data;
  }

  private static processAsins(asins: DsSelectOption[]): string {
    if (asins.length > 0) {
      const asinsValue = asins.map((asin) => {
        return asin.value;
      });
      return asinsValue.toString();
    }
    return "";
  }

  private static setKeywords(keywords: DsSelectOption[]): DsSelectOption[] {
    const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (keywords.length > 0) {
      return keywords.filter(
        (keyword) => !ids.includes(Number(keyword.value))
      ) as DsSelectOption[];
    }
    return [];
  }

  private static setShortcutKeywords(
    keywords: DsSelectOption[]
  ): DsSelectOption[] {
    const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (keywords.length > 0) {
      return keywords.filter((keyword) =>
        ids.includes(Number(keyword.value))
      ) as DsSelectOption[];
    }
    return [];
  }
}
